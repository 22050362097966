// project import

import { Box } from "@mui/material";
import SimpleBarScroll from "../../../../components/Dashboard/third-party/SimpleBar";
import Navigation from "./Navigation";
import Logo from "../../../images/LabtinLogo.jpg";
import { Link } from "react-router-dom";
// import SimpleBar from "../../..//components/third-party/SimpleBar";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = ({ open }) => (
  <SimpleBarScroll
    sx={{
      "& .simplebar-content": {
        display: "flex",
        flexDirection: "column",
      },
    }}
  >
    <Navigation open={open} />
    <Link to="/">
      <div
        style={{
          width: "100%",
          marginTop: "2rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "10rem", marginLeft: "2rem" }}
        />
      </div>
    </Link>
  </SimpleBarScroll>
);

export default DrawerContent;
