import { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import axios from "axios";
import gallery from "../image/gallery.png";
import {
  Avatar,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { API } from "../api";
import { Person } from "@mui/icons-material";

const RegisterUser = ({ number }) => {
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [img, setImg] = useState("");
  const [age, setAge] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loc = useLocation();
  const data = loc.state;

  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const { error, userInfo } = userRegister;
  console.log(number);

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);
  const message = `Hi ${name},%0aWelcome To the GK DIAGNOSTIC Family, Now You Can Avail Upto 50% Off! On All Blood Tests & Packages If any assisstance Call Us 8686073073 24x7! `;
  // const message =
  //   "Welcome to our diagnostic service! We're excited to have you as a new member of our community. As a token of our appreciation, please use the coupon code 'FIRSTORDER' at checkout to receive 40% off your first order. We look forward to serving you and helping you achieve your healthcare goals. If you have any questions or concerns, don't hesitate to reach out. Thanks for choosing us as your trusted healthcare provider!";
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      register({
        name,
        age,
        sex: gender,
        email,
        img,
        number: number.replace(/\+91|\D/g, ""),
      })
    );
    await axios.get(
      `https://whatsapp.labauto.in/api/send?number=91${number.replace(
        /\+91|\D/g,
        ""
      )}&type=text&message=${message}&instance_id=66A47DFC879C5&access_token=66979e95d92b0`
    );
    setLoading(false);
  };
  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);

    const { data } = await API.post("/upload", formData);
    console.log(data);

    setImg(data[0]);
  };

  console.log(name, age, gender, img, number.replace(/\+91|\D/g, ""));

  return (
    <>
      <Stack
        mt={2}
        p={{ xs: 2, sm: 4 }}
        alignItems="center"
        spacing={1}
        sx={{ width: { xs: "20rem", sm: "25rem" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
          }}
        >
          <Avatar src={img} sx={{ cursor: "pointer" }}>
            {!img && <Person fontSize="large" onClick={handleClick} />}
          </Avatar>

          <input
            type="file"
            multiple
            ref={hiddenFileInput}
            style={{ display: "none" }}
            onChange={uploadFileHandler}
          />
        </div>
        <Typography color="#2645a3" fontSize="1.1rem" fontWeight="700">
          Enter your Details
        </Typography>
        <FormControl fullWidth>
          <Stack spacing={5} sx={{ width: "100%" }}>
            <TextField
              name="UsersName"
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
              label="Name"
            />
            <TextField
              name="Email"
              placeholder="Enter your Email"
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
            />

            <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-label">Gender</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                value={gender}
                label="Gender"
                onChange={(e) => setGender(e.target.value)}
              >
                <MenuItem value="MALE">MALE</MenuItem>
                <MenuItem value="FEMALE">FEMALE</MenuItem>
                <MenuItem value="OTHER">OTHER</MenuItem>
              </Select>
            </FormControl>
            <TextField
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              type="number"
              label="Age"
              variant="outlined"
              placeholder="Enter your age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            />
            <Button
              disabled={Boolean(
                name === "" && age === "" && gender === "" && email === ""
              )}
              size="large"
              variant="contained"
              onClick={submitHandler}
              loading={loading}
            >
              Continue
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </>
  );
};

export default RegisterUser;
