import "./style.css";
import CallIcon from "@mui/icons-material/Call";
import DraftsIcon from "@mui/icons-material/Drafts";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SendIcon from "@mui/icons-material/Send";
import Logo from "../../image/gklogo.jpg";
import { Button, IconButton } from "@mui/material";
import { Facebook, Twitter, Instagram } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer-sectio desktopElement">
      <div className="container">
        <div className="footer-cta">
          <div style={{ marginBottom: "1rem" }} className="row ">
            <div>
              <div className="single-cta">
                <LocationOnIcon fontSize="large" />
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span>D.No : 108, opposite Sri Sara Hospital, Bairagi patteda, Tirupati, Andhra Pradesh 517501
                    </span>
                </div>
              </div>
            </div>
            <div>
              <div className="single-cta">
                <CallIcon fontSize="large" />
                <div className="cta-text">
                  <h4>Call us</h4>
                  

                  <a color="black" href="tel:09441805252" >
                  <span>094418 05252</span>
        </a>
                  {/* <span>086 86 073 073</span> */}
                </div>
              </div>
            </div>
            <div>
              <div className="single-cta">
                <DraftsIcon fontSize="large" />
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>admin@gkdiagnostic.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content1">
          <div className="row">
            <div>
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="index.html">
                    <img src={Logo} className="img-fluid" alt="logo" />
                  </a>
                </div>
                <div className="footer-text">
                  <p>
                  "GK diagnostic in Tirupati, having a important 'Reach', 
    providing superior quality diagnosis services to its customers through a really 
    economical network of labs and assortment points. Our vision is to supply top quality
     tests and outcomes at cheap costs to the plenty. We are trust worthy and efficient."
                  </p>
                </div>
                <div className="footer-social-icon">
                  <span>Follow us</span>

                  <IconButton 
                  onClick={() => window.open("https://facebook.com")}>
                    <Facebook />
                  </IconButton>
                  <IconButton >
                    <Twitter />
                  </IconButton>
                  <IconButton >
                    <Instagram />
                  </IconButton>
                </div>
              </div>
            </div>
            <div>
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li>
                    <a href="https://www.gkdiagnostic.com/">Home</a>
                  </li>
                  <li>
                    <a href="/BookingPackages">Health Tests</a>
                  </li>
                  <li>
                    <a href="/BookingPackages">Health Packages</a>
                  </li>
                  <li>
                    <a href="#">Radiology</a>
                  </li>
                  <li>
                    <a href="#">Phlebo Services</a>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div>
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  {/* <h3>Download</h3>
                </div>

                <div className="google-img">
                  <img src="/google-play-badge.png" alt="Google" onClick={() => window.open("https://google.com")} />
                </div>
                <div className="apple-img">
                  <img src="/app-store.png" alt="Google" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div>
              <div className="copyright-text">
                <p>
                  Copyright &copy; 2023, All Right Reserved{" "}
                  <a href="https://www.gkdiagnostic.com/">GK DIAGNOSTIC</a>
                </p>
              </div>
            </div>
            <div>
              <div className="footer-menu1">
                <ul>
                  <li style={{ cursor: "pointer" }}>
                    <a href="/">Home</a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a href="/about">About</a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a href="/terms">Terms</a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a href="/privacypolicy">Privacy</a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a href="/refundpolicy">Refund</a>
                  </li>
                  <li style={{ cursor: "pointer" }}>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
