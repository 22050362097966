import { filter } from "lodash";
import { useEffect, useState } from "react";
// @mui
import {
  Card,
  Table,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Backdrop,
  Modal,
  Fade,
  Box,
  useMediaQuery,
  Divider,
  Slide,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  styled,
  alpha,
  Stack,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, NavigateNext, Search } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { getPrescriptions } from "../../../actions/prescriptionActions";

// ----------------------------------------------------------------------

const style = {
  paddingBottom: "3rem",
  position: "absolute",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 3,
};

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 45,
  margin: 20,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "center" },
  { id: "mobileNumber", label: "Mobile Number", align: "center" },
  { id: "images", label: "Images", align: "center" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

// prescriptions

export default function Analytics() {
  const [open2, setOpen2] = useState(false);
  const isMobile = useMediaQuery("(max-width:550px)");
  const dispatch = useDispatch();

  const { isLoading, prescriptions } = useSelector(
    (state) => state.prescriptions
  );
  console.log(prescriptions);

  useEffect(() => {
    dispatch(getPrescriptions());
  }, [dispatch]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleClose1 = () => {
    setOpen2(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = prescriptions.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleClick1 = () => {};

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - prescriptions.length) : 0;

  const filteredUsers = applySortFilter(
    prescriptions,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers?.length && !!filterName;

  // name

  const navigate = useNavigate();

  return !isLoading ? (
    <>
      <Box sx={{ m: 0, p: 0, maxWidth: 1350 }}>
        <Card>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <StyledSearch
              value={filterName}
              onChange={handleFilterByName}
              placeholder="Search User..."
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Box>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 900, maxWidth: "100%" }}>
              <Table size={isMobile ? "small" : "medium"}>
                <TableHead sx={{ width: "100%" }}>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <TableCell
                        sx={{ lineHeight: "2.5rem" }}
                        key={headCell.id}
                        align="center"
                      >
                        <TableSortLabel hideSortIcon>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { _id, name, mobile, img, files } = row;
                      const selectedUser = selected.indexOf(_id) !== -1;
                      console.log(_id);

                      return (
                        <>
                          <TableRow
                            sx={{ fontSize: "1rem" }}
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={selectedUser}
                          >
                            {/* <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                                whiteSpace: "nowrap",
                              }}
                              align="center"
                            >
                              <img
                                src={`https://server.labtin.com/${icon}`}
                                width="80px"
                                alt=""
                                style={{ margin: "0 auto" }}
                              />
                            </TableCell> */}

                            {/* <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins, sans-serif",
                                  fontSize: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                                variant="subtitle2"
                                noWrap
                                align="center"
                              >
                                {title}
                              </Typography>
                            </TableCell> */}
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              align="center"
                            >
                              {name}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              align="center"
                            >
                              {mobile}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "1rem",
                              }}
                              align="center"
                            >
                              <Box sx={{ display: "flex", gap: 1 }}>
                                {files.map((file) => (
                                  <img
                                    src={`https://server.gkdiagnostic.com/${file}`}
                                    // maxWidth="100px"
                                    alt=""
                                    style={{
                                      margin: "0 auto",
                                      maxHeight: "100px",
                                      maxWidth: "100px",
                                    }}
                                  />
                                ))}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={prescriptions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

// import React, { useEffect } from 'react'
// import '../../../App.css'
// import './PrescriptionUploaded.css'
// import { useNavigate } from 'react-router-dom'
// import PrescriptionRow from './PrescriptionRow'
// import { useDispatch, useSelector } from 'react-redux'
// import { getPrescriptions } from '../../../actions/prescriptionActions'

// const PrescriptionUploaded = () => {
//   const navigate = useNavigate()
//   const dispatch = useDispatch()

//   const { isLoading, prescriptions } = useSelector(
//     (state) => state.prescriptions
//   )
//   console.log(prescriptions)

//   useEffect(() => {
//     dispatch(getPrescriptions())
//   }, [dispatch])
//   return (
//     <div className='prescription_uploaded'>
//       <div className='table-container'>
//         <table>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Mobile Number</th>
//               <th>Images</th>
//             </tr>
//           </thead>
//           <tbody>
//             {prescriptions.map((d) => (
//               <PrescriptionRow
//                 name={d.name}
//                 mobile={d.mobile}
//                 img={d.img}
//                 files={d.files}
//               />
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   )
// }

// export default PrescriptionUploaded
