import React, { useEffect, useState } from "react";
import "./ShowLabs.css";
import DSlider from "./DSlider";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLabs } from "../actions/labActions";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  styled,
  Button,
  Backdrop,
  Slide,
  Modal,
  IconButton,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Close, NavigateBefore } from "@mui/icons-material";
import { loginPopup } from "../actions/userActions";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#1976d2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const style = {
  position: "absolute",
  overflowY: "auto",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const ShowLabs = () => {
  const loc = useLocation();
  const data = loc.state.data;
  const avail_labs = loc.state.avail_labs;
  const arr = Object.values(data);
  const dispatch = useDispatch();
  const [info, setInfo] = React.useState({});
  const [selectedLab, setSelectedLab] = React.useState("");
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });
  const [open1, setOpen1] = useState(false);
  const matches = useMediaQuery("(min-width:550px)");
  // const [selected, setSelected] = useState([]);

  const { labList } = useSelector((state) => state.labList);
  const { filter } = useSelector((state) => state.filter);
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(getLabs());
  }, [dispatch]);

  const { vertical, horizontal, open } = state;

  var available_labs;
  const cartItems = new Map();
  var availableIn;
  var testTitles = [];
  var selected = [];

  const myValuesArray = Array.from(data.values());
  const set = new Set();
  for (const x of myValuesArray) {
    x.availableIn.map((m) => set.add(m.lab));
  }

  let filt = [];

  if (avail_labs) {
    const filtered = labList.filter((f) => avail_labs.includes(f._id));
    console.log(filtered);
    for (const x of myValuesArray) {
      const a = x.availableIn.filter((f) => avail_labs.includes(f.lab));
      console.log(a);
      for (const y of a) {
        filt.push({ ...x, availableIn: y });
      }
    }
  } else {
    for (const x of myValuesArray[0].availableIn) {
      filt.push({ ...myValuesArray[0], availableIn: x });
    }
  }

  if (avail_labs) {
    for (const x of data.values()) {
      testTitles.push(x.title);
      selected.push(x);
      x.availableIn
        .filter((f) => avail_labs.includes(f.lab))
        .map((m) => {
          if (cartItems.has(m.lab)) {
            cartItems.set(m.lab, cartItems.get(m.lab) + m.discountPrice);
          } else {
            cartItems.set(m.lab, m.discountPrice);
          }
        });
    }
    available_labs = labList.filter((f) => avail_labs.includes(f._id));
  } else {
    var dat;
    for (const x of data.values()) {
      dat = x;
      testTitles.push(x.title);
      selected.push(x);
    }
    const { availableIn: inData } = dat;
    availableIn = inData;
    const availableInArray = availableIn.map((m) => m.lab);
    available_labs = labList.filter((f) => availableInArray.includes(f._id));
  }

  const Labsdiv = (lab) => {
    return (
      <div onClick={() => setSelectedLab(lab._id)} className="container1">
        <div className="labs_div">
          <div className="lab_logo">
            <div className="lab_logo_img">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={`https://server.gkdiagnostic.com/${lab.logo}`}
                onError={(event) => {
                  event.target.src = "/noImage.png";
                  event.onerror = null;
                }}
              />
            </div>
          </div>
          <div className="labs_name">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>{lab.title}</h2>
              <Button
                sx={{
                  display: { xs: "none", sm: "block" },
                  textTransform: "none",
                  mb: "12px",
                  mt: "-5px",
                }}
                onClick={() => {
                  setSelectedLab(lab._id);
                  setOpen1(true);
                }}
              >
                (View Cart)
              </Button>
            </Box>
            <p>{lab.accrediation} Accredited</p>

            <p className="desktop-element">E-Report : {lab.time}Hours</p>
          </div>
          <div className="labs_price">
            {avail_labs ? (
              <h3>₹{cartItems.get(lab._id)}</h3>
            ) : (
              <h3>
                ₹{availableIn.find((f) => f.lab == lab._id).discountPrice}
              </h3>
            )}
            <input
              className="selectLabRadio"
              type="radio"
              onClick={handlecheckbox}
              name="rad"
              value={lab._id}
            />
          </div>
        </div>
        <Stack
          direction="row"
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <p className="mobile-element">E-Report : {lab.time}Hours</p>
          <Button
            sx={{ display: { xs: "block", sm: "none" }, textTransform: "none" }}
            onClick={() => {
              setSelectedLab(lab._id);
              setOpen1(true);
            }}
          >
            View Cart
          </Button>
        </Stack>
      </div>
    );
  };

  const handlecheckbox = (e) => {
    const l = available_labs.find((l) => l._id == e.target.value);
    const r = avail_labs
      ? cartItems.get(e.target.value)
      : availableIn.find((l) => l.lab == e.target.value);
    setInfo({ ...info, test_titles: testTitles, lab: l, price: r });
    setState({ ...state, open: true });
  };

  const action = (
    <Button
      onClick={() => {
        if (userInfo) {
          navigate("/Booking", { state: { info } });
        }
        dispatch(loginPopup(true));
      }}
      variant="contained"
    >
      Next
    </Button>
  );
  const filtered_labs = available_labs.filter(
    (m) => m.state?.toLowerCase() == filter?.toLowerCase()
  );
  console.log(filtered_labs, "plp");

  const steps = ["Find Test", "Select Lab", "Book Slot", "Checkout"];

  return (
    <div className="showLabs_main">
      <Box sx={{ width: "100%", pt: "2rem", pb: { xs: "0rem", sm: "2rem" } }}>
        <Stepper activeStep={1} alternativeLabel connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <div className="showLabs_main_div">
        {filtered_labs.length > 0 ? (
          <div className="showLabs_list">
            <h2>Select Labs Available in {filter} </h2>
            {filtered_labs.map((l) => Labsdiv(l))}
          </div>
        ) : (
          <div className="showLabs_list">No Labs Available</div>
        )}
      </div>
      <Snackbar
        sx={{ marginBottom: "5rem", fontSize: "1.2rem", zIndex: 100 }}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message="Lab Selected"
        action={action}
        key={vertical + horizontal}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={() => setOpen1(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ display: "flex", justifyContent: " flex-end" }}
      >
        <Slide
          direction={matches ? "left" : "up"}
          timeout={500}
          in={open1}
          mountOnEnter
          unmountOnExit
        >
          <Box
            p={{ xs: 0, sm: 2 }}
            width={{ xs: "100%", sm: 300, md: 400 }}
            height={{ xs: "70%", sm: "100%" }}
            top={{ xs: "30%", sm: "0" }}
            sx={style}
          >
            <IconButton
              onClick={() => setOpen1(false)}
              sx={{
                // zIndex: 2,
                padding: "5px",
                position: "absolute",
                top: 1,
                right: 1,
                background: "white",
                ":hover": { background: "white" },
              }}
            >
              <Close fontSize="large" />
            </IconButton>
            <Stack spacing={3} p={2} mt={2}>
              <Typography fontSize="1rem" fontWeight="700" color="#616161">
                Selected Tests
              </Typography>
              <Stack spacing={2} px={2}>
                {filt
                  .filter((f) => f.availableIn.lab === selectedLab)
                  ?.map((d) => (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography fontSize="1rem" fontWeight="600">
                        {d?.title}
                      </Typography>
                      <Typography fontSize="1rem" fontWeight="700">
                        ₹{d?.availableIn.discountPrice}
                      </Typography>
                    </Box>
                  ))}
              </Stack>
            </Stack>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default ShowLabs;
