import React, { useEffect, useState } from "react";
import "./Login.css";
import Logo from "../image/gklogo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import axios from "axios";
import { API } from "../api";
import OtpInput from "react-otp-input";
import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Button, Box, FormControl, TextField } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import RegisterUser from "./RegisterUser";

const Login = ({ history, setRegister, number, setNumber }) => {
  const [active, setActive] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [genOtp, setGenOtp] = useState("");
  const [msg, setMsg] = useState("");
  const [disableOtpButton, setDisableOtpButton] = useState(false);
  const [timer, setTimer] = useState(30);
  const [timerOpen, setTimerOpen] = useState(false);
  const [disableTimer, setDisableTimer] = useState();
  const [intervalId, setIntervalId] = useState(null);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  let myInterval,
    disableOTPmilliseconds = 5000;

  const handleClearInterval = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    return handleClearInterval;
  }, []);

  const getOtp = async () => {
    var otp = Math.floor(1000 + Math.random() * 9000);
    setOtp(otp);
    setGenOtp(otp);
    await axios.get(
      `https://api.authkey.io/request?authkey=1a0b1e1379d5de53&mobile=${Number(
        number.replace(/\+91|\D/g, "")
      )}&country_code=+91&sid=9267&otp=${otp}&company=GK DIAGNOSTICS`
    );
    setActive(true);
    clearInterval(intervalId);
    // console.log(otp);
    setTimer(30);
    const id = setInterval(() => {
      setTimer((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(intervalId);
          return prevCounter;
        }
        return prevCounter - 1;
      });
    }, 1000);
    setIntervalId(id);

    setDisableOtpButton(true);

    myInterval = setInterval(() => {
      return setDisableTimer((prev) => prev - 1);
    }, 1000);

    setTimeout(() => {
      setDisableOtpButton(false);
      setDisableTimer(disableOTPmilliseconds / 1000);

      clearInterval(myInterval);
    }, disableOTPmilliseconds);
  };
  const verify = async () => {
    console.log(number?.replace("+91", "").replace(" ", "").replace("-", ""));

    const num = number?.replace("+91", "").replace(" ", "").replace("-", "");

    console.log(parseInt(number));
    const { data } = await API.get(`/users/verify/${num}`);
    console.log(data);

    data.status ? dispatch(login(num)) : setRegister(true);
  };

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;
  // useEffect(() => {
  //   if (userInfo) {
  //     history.push("/");
  //   }
  // }, [history, userInfo]);
  const submitHandler = (e) => {
    e.preventDefault();
    otp === parseInt(enteredOtp) ? verify() : setMsg("Invalid OTP,");
  };

  return (
    <div className="login_main_div">
      <div className="login_main">
        <div className="login_heading">
          <h1>Get Started, Login/SignUp</h1>
        </div>
        <div className="login_form">
          <div className="login_form_entry">
            <label className="num-label" htmlFor="email">
              Enter your phone Number
            </label>
            <FormControl sx={{ width: { xs: "20rem", sm: "25rem" } }}>
              <MuiPhoneNumber
                size="small"
                variant="outlined"
                defaultCountry={"in"}
                onChange={(e) => setNumber(e)}
                countryCodeEditable={false}
                onlyCountries={["in"]}
              />
              <label className="helper-text">
                A 4-digit OTP will be sent via SMS to verify your account
              </label>
            </FormControl>
            {/* <button
              className="btn"
              onClick={getOtp}
              // disabled={disableOtpButton}
              disabled
            >
              Get Otp
            </button> */}
            {!active && (
              <Button
                fullWidth={false}
                sx={{ width: "7rem", mt: "1rem" }}
                variant="contained"
                disabled={number.replace(/\+91|\D/g, "").length !== 10}
                onClick={getOtp}
              >
                Get Otp
              </Button>
            )}
            {/* {disableOtpButton && <p>Resend otp in 30sec</p>} */}
          </div>

          {active && (
            <div className="login_form_entry">
              {timer > 0 ? (
                <div style={{ textAlign: "center", marginBottom: "1rem" }}>
                  Resend Otp in {timer}
                </div>
              ) : (
                <Button onClick={getOtp}>Resend Otp</Button>
              )}
              <h1>Otp Verification</h1>
              <label className="otp-label" htmlFor="userPassword">
                Enter the otp you received on {number}
              </label>

              <OtpInput
                value={enteredOtp}
                isInputNum={true}
                onChange={(e) => setEnteredOtp(e)}
                numInputs={4}
                separator={<span style={{ margin: "0 10px" }}></span>}
                inputStyle={{
                  width: "30px",
                  height: "30px",
                  fontSize: "17px",
                }}
                containerStyle={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button variant="contained" onClick={submitHandler}>
                  Continue
                </Button>
              </Box>
            </div>
          )}
          {/* <div className="signUp_sec">
            <p>Don't have a account? </p>
            <Link to="/Register"> SignUp</Link>
          </div> */}
        </div>
        <div className="header_logo loginfooter">
          <img src={Logo} onClick={() => navigate("/")} alt="Logo" />
        </div>
      </div>
      {msg && <h3 className="wError">{msg}</h3>}
      {error ? <p className="wError">{error}</p> : null}
    </div>
  );
};

export default Login;
