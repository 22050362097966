import { useState, useEffect } from "react";
import "./Header.css";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import NoteAddRoundedIcon from "@mui/icons-material/NoteAddRounded";
import Logo from "../image/gklogo.jpg";
import HomeIcon from "@mui/icons-material/Home";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation, useParams } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import PrescriptionDialog from "./PrescriptionDialog";
import { useNavigate } from "react-router-dom";
import CircleImage from "./CircleImage";
import Tick from "../image/21.png";
import { useDispatch, useSelector } from "react-redux";
import { loginPopup, logout, searchKeyword } from "../actions/userActions";
import { getLocations } from "../actions/packageActions";
import { filter } from "../actions/labActions";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import $ from "jquery";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  useMediaQuery,
} from "@mui/material";
import RegisterUser from "./RegisterUser";
import Login from "./Login";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
};

const Header = () => {
  const [show, setShow] = useState(false);
  const [number, setNumber] = useState("");
  const location = useLocation();
  const state = location.state;
  const { popUp } = useSelector((state) => state.popup);
  const [open, setOpen] = useState(popUp);
  const [register, setRegister] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const placeholders = [
    "Search Lab Test Eg: Thyroid Profile",
    "Search Lab Test Eg: FBS",
    "Search Lab Test Eg: CBC",
    "Search Lab Test Eg: Full body checkup",
    "Search Lab Test Eg: Lipid Profile",
    "Search Lab Test Eg: HbA1C",
    "Search Lab Test Eg: Fever panel",
    "Search Lab Test Eg: Vitamin D",
  ];
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPlaceholderIndex(
        (currentIndex) => (currentIndex + 1) % placeholders.length
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);
  // const [index, setIndex] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { locationList } = useSelector((state) => state.locations);
  const { userInfo } = userLogin;
  // console.log(userInfo, open);
  const pathname = useLocation().pathname;

  const searchKey = useSelector((state) => state.search);
  const { search } = searchKey;

  useEffect(() => {
    if (!pathname.includes("/search/")) {
      dispatch(searchKeyword(""));
    }
  }, [pathname]);

  // useEffect(() => {
  //   setOpen(popUp);
  // }, [popUp]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  const handlenav1 = (e) => {
    e.preventDefault();
    navigate(`/BookingPackages`, {
      state: { name: "Health Packages" },
    });
  };
  const handlenav2 = (e) => {
    e.preventDefault();
    navigate(`/BookingPackages`, {
      state: { name: "Health Tests" },
    });
  };
  const handlenav3 = (e) => {
    e.preventDefault();
    navigate(`/BookingPackages`, {
      state: { name: "Scan&Imaging" },
    });
  };

  const handleLogout = (e) => {
    dispatch(logout());
  };
  const searchHandler = (e) => {
    e.preventDefault();
    if (search?.length > 0) {
      navigate(`/search/${search}`);
    }
  };

  return (
    <div className="header_div">
      <div className="header_div">
        <div className="header_main">
          <PrescriptionDialog
            className="mymodal"
            overlayClassName="myoverlay"
            visibility={show}
            onClose={async () => {
              setShow(false);
            }}
          />
          {/* 
          <PrescriptionDialog
            visility={show}
            onClose={async () => {
              setShow(false);
            }}
          /> */}

          <Link to="/">
            <div className="header_logo">
              <img src={Logo} alt="Logo" />
            </div>
          </Link>
          {/* <div className="header_menu">
          <div className="menuItems">
            <Link to="/">
              <HeaderOptions Icon={HomeSharp} Title={"Home"} />
            </Link>
            <Link to="/Booking">
              <HeaderOptions
                Icon={CalendarMonthRoundedIcon}
                Title={"Bookings"}
              />
            </Link>
            <Link to="/MyReport">
              <HeaderOptions Icon={SummarizeRoundedIcon} Title={"My Report"} />
            </Link>
            <Link to="/Support">
              {" "}
              <HeaderOptions Icon={SupportAgentRoundedIcon} Title={"Support"} />
            </Link>
            <LeftDrawer />
          </div>
        </div> */}
          <div className="searchwithPrescreption">
            <div className="searchbar">
              <input
                className="searchbar_input"
                id="search-input"
                onChange={(e) => dispatch(searchKeyword(e.target.value))}
                onFocus={() =>
                  !pathname.includes("/search/") &&
                  navigate(`/search/${search}`)
                }
                type="text"
                placeholder={placeholders[currentPlaceholderIndex]}
                value={search}
              />
              <div className="searchIcon">
                <SearchTwoToneIcon
                  sx={{ fontSize: "1.5rem", mr: 2 }}
                  color="inherit"
                  onClick={searchHandler}
                />
              </div>
            </div>
            <div
              className="uploadPrescription"
              onClick={async () => {
                setShow(true);
              }}
            >
              {/* <HeaderOptions Icon={NoteAddRoundedIcon} Title={"Prescription"} /> */}
              {/* <NoteAddRoundedIcon fontSize="large" /> */}

              <img
                style={{ maxHeight: "2.3rem" }}
                src="/prescription.png"
                alt="Prescription"
              />
            </div>

            <div className="selectState">
              <FormControl size="small">
                <Select
                  onChange={(e) => dispatch(filter(e.target.value))}
                  defaultValue="Tirupati"
                  name="state"
                  size="small"
                  id="sState"
                >
                  {locationList.map((l, i) => (
                    <MenuItem
                      key={i}
                      style={{ padding: "0.5rem 1rem" }}
                      value={l?.city}
                    >
                      {l?.city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="header_div_desktop">
          <div className="headerforDesktop">
            <button onClick={handlenav1}>Health Packages</button>
            <button onClick={handlenav2}>Blood Tests</button>
            <button onClick={handlenav3}>Health Scans & Imaging</button>
          </div>
          <div className="header_profile" /*onClick={handledrop}*/>
            <MenuIcon fontSize="large" />
            <p>Menu</p>
          </div>
          <div className="action" id="action">
            <div className="menu ">
              <CircleImage
                handleClick={() => dispatch(loginPopup(true))}
                name={userInfo?.name}
                number={userInfo?.number}
              />
              <ul>
                <li>
                  <Link to="/">
                    <div className="sidebar_icons1">
                      <HomeIcon /> <span className="icon_name1">Home</span>
                    </div>
                  </Link>
                </li>
                {userInfo?.isAdmin ? (
                  <li>
                    <Link to="/dashboard">
                      <div className="sidebar_icons1">
                        <CalendarMonthIcon />{" "}
                        <span className="icon_name1">Dashboard</span>
                      </div>
                    </Link>
                  </li>
                ) : (
                  <>
                    <li>
                      <Link
                        onClick={() => {
                          if (userInfo) navigate("/MyBooking");
                          else dispatch(loginPopup(true));
                        }}
                        to="/MyBooking"
                      >
                        <div className="sidebar_icons1">
                          <CalendarMonthIcon />{" "}
                          <span className="icon_name1">My Bookings</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          if (userInfo) navigate("/MyReport");
                          else dispatch(loginPopup(true));
                        }}
                        to="/MyReport"
                      >
                        <div className="sidebar_icons1">
                          <SummarizeRoundedIcon />{" "}
                          <span className="icon_name1">My Reports</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          if (userInfo) navigate("/MyAddress");
                          else dispatch(loginPopup(true));
                        }}
                        to="/MyAddress"
                      >
                        <div className="sidebar_icons1">
                          <LocationOnRoundedIcon />{" "}
                          <span className="icon_name1">Address</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          if (userInfo) navigate("/MyOffers");
                          else dispatch(loginPopup(true));
                        }}
                        to="/MyOffers"
                      >
                        <div className="sidebar_icons1">
                          <LocalOfferIcon />{" "}
                          <span className="icon_name1">Offers</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        <div className="sidebar_icons1">
                          <HelpIcon /> <span className="icon_name1">Help</span>
                        </div>
                      </Link>
                    </li>
                  </>
                )}
                {userInfo && (
                  <li>
                    <Link to="/Logout">
                      <div className="sidebar_icons1">
                        <LogoutIcon />{" "}
                        <span className="icon_name1" onClick={handleLogout}>
                          Logout
                        </span>
                      </div>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={popUp && !userInfo}
        onClose={() => {
          dispatch(loginPopup(false));
          setRegister(false);
        }}
      >
        <Box sx={style}>
          <IconButton
            onClick={() => dispatch(loginPopup(false))}
            sx={{ position: "absolute", top: 0, right: 0, zIndex: 3 }}
            className="wrong-icon"
          >
            <Close />
          </IconButton>
          {register ? (
            <RegisterUser number={number} setNumber={setNumber} />
          ) : (
            <Login
              number={number}
              setNumber={setNumber}
              setRegister={setRegister}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Header;
