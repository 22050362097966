import {
  Box,
  Button,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createScrollmenu } from "../../../actions/appearanceActions";
import { API } from "../../../api";
import CustomLink from "../../../components/CustomLink";
import FormHeading from "../../../components/FormHeading";
import "./AddScrollmenu.css";

const AddScrollmenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");

  const handleAddScrollmenu = async (e) => {
    e.preventDefault();

    dispatch(createScrollmenu(data, navigate));
  };
  const [data, setData] = useState({
    title: "",
    icon: "",
  });

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const { data: info } = await API.post("/upload", formData);
    console.log(info[0]);
    setData({
      ...data,
      icon: info[0],
    });
  };
  return (
    <Stack p={4} spacing={matches ? 3 : 1}>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontSize: "1.4rem", fontWeight: "700" }}
      >
        Add Scrollmenu
      </Typography>
      <Divider />
      <Stack>
        <FormControl>
          <Stack spacing={4}>
            <TextField
              size={matches ? "medium" : "small"}
              label="Title"
              variant="outlined"
              placeholder="Enter the Title"
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
            <Box display="flex" alignItems="center">
              <Button variant="contained" component="label" sx={{ mr: 5 }}>
                Upload
                <input
                  onChange={uploadFileHandler}
                  style={{ display: "none" }}
                  type="file"
                  name="bannerImage"
                  id="bannerImage"
                />
              </Button>
              {data.icon && (
                <img
                  src={`https://server.gkdiagnostic.com/${data.icon}`}
                  height={50}
                  alt="not found"
                />
              )}
            </Box>

            <Button
              disabled={data.title === "" || data.icon === ""}
              variant="contained"
              onClick={handleAddScrollmenu}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default AddScrollmenu;
