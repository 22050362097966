import { Box, Stack, Typography } from "@mui/material";

export default function Contact() {
  return (
    <Box
      //   padding={4}
      width={{ xs: "93%", sm: "80%", md: "60%" }}
      //   width="978px"
      margin="1rem auto"
      padding="32px"
      pb={{ xs: "8rem", sm: "1rem" }}
      backgroundColor="#f1f3f6"
      borderRadius="8px"
    >
      <Typography
        fontSize="1.5rem"
        fontWeight="bold"
        textAlign="center"
        color="#002984"
        marginBottom="1rem"
        url="https://www.apple.com/legal/images/icon_dataprivacy_2x.png"
      >
        CONTACT
      </Typography>
      <Stack spacing={3}>
        <Typography>
          Thank you for considering GK Diagnostic for your diagnostic needs.
        </Typography>
        <Typography>
          We are dedicated to providing you with accurate and timely results,
          and are committed to your satisfaction. If you have any questions or
          concerns regarding our services, please do not hesitate to contact us.
          Our customer care team is available to assist you in any way possible.
          <br></br>
          You can reach us at <b>094418 05252</b> or email us at{" "}
          <b>admin@gkdiagnostic.com</b>, We will do our best to respond to your inquiry
          as soon as possible. Thank you for choosing GK Diagnostic for your diagnostic
          needs. We look forward to serving you.
        </Typography>
      </Stack>
    </Box>
  );
}
