import { Box, Stack, Typography } from "@mui/material";

export default function Terms() {
  return (
    <Box
      //   padding={4}
      width={{ xs: "75%", sm: "80%", md: "60%" }}
      //   width="978px"
      margin="1rem auto"
      padding="32px"
      backgroundColor="#f1f3f6"
      borderRadius="8px"
    >
      <Typography
        fontSize="1.5rem"
        fontWeight="bold"
        textAlign="center"
        color="#002984"
        marginBottom="1rem"
        url="https://www.apple.com/legal/images/icon_dataprivacy_2x.png"
      >
        TERMS AND CONDITIONS
      </Typography>
      <Stack spacing={3}>
        <Typography>Welcome to GK Diagnostic!</Typography>
        <Typography>
          These terms and conditions outline the rules and regulations for the
          use of GK Diagnostic Website, located at https://www.gkdiagnostic.com
        </Typography>
        .
        <Typography>
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use GK Diagnostic if you do not agree
          to take all of the terms and conditions stated on this page.
        </Typography>
        <Typography>
          All terms refer to the offer, acceptance and consideration of payment
          necessary to undertake the process of our assistance to the Client in
          the most appropriate manner for the express purpose of meeting the
          Client's needs in respect of provision of the Company's stated
          services, in accordance with and subject to, prevailing law of
          Netherlands. Any use of the above terminology or other words in the
          singular, plural, capitalization and/or he/she or they, are taken as
          interchangeable and therefore as referring to same.
        </Typography>
      </Stack>
    </Box>
  );
}
