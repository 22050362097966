import { Box, Stack, Typography } from "@mui/material";

export default function RefundPolicy() {
  return (
    <Box
      //   padding={4}
      width={{ xs: "75%", sm: "80%", md: "60%" }}
      //   width="978px"
      margin="1rem auto"
      padding="32px"
      backgroundColor="#f1f3f6"
      borderRadius="8px"
    >
      <Typography
        fontSize="1.5rem"
        fontWeight="bold"
        textAlign="center"
        color="#002984"
        marginBottom="1rem"
        url="https://www.apple.com/legal/images/icon_dataprivacy_2x.png"
      >
        REFUND POLICY
      </Typography>
      <Stack spacing={2}>
        <Typography>
          We at GK Diagnostic strive to provide our customers with the highest quality
          diagnostic services. However, if for any reason you are not satisfied
          with our services, we offer a refund in accordance with our refund
          policy
        </Typography>
        <Typography>
          We collect personal information that you voluntarily provide to us
          when using our website, such as your name, email address, phone
          number, and other contact information. We may also collect information
          about your computer or mobile device, such as your IP address, browser
          type, operating system, and device identifiers.
        </Typography>
        <Stack>
          <Typography>
            Refunds will only be issued within 30 days of the date of purchase.
          </Typography>
          <Typography>
            To be eligible for a refund, you must provide a valid reason for
            your request and submit it in writing to our customer service team.
          </Typography>
          <Typography>
            Refunds will only be issued if we are unable to resolve the issue
            you are experiencing with our services.
          </Typography>
          <Typography>
            If a refund is approved, we will issue the refund to the original
            payment method used for the purchase.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
