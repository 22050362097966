import { Close } from "@mui/icons-material";
import {
  Box,
  Flexbox,
  Stack,
  Card,
  Typography,
  Button,
  CardContent,
  Modal,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0.4rem",
  boxShadow: "0 0.5rem 1rem rgba(69, 69, 69, 0.5)",
  p: 4,
  minWidth: 300,
};

export default function PackageCard(props) {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const { filter } = useSelector((state) => state.filter);

  const [message, setMessage] = useState("Book Now");
  const addToCart = (e) => {
    if (props.cart.has(props.data._id)) {
      props.cart.delete(props.data._id);
      props.cart.size > 0
        ? props.setState({ ...props.state, open: true })
        : props.setState({ ...props.state, open: false });
      setMessage("Book Now");
    } else {
      var ct = props.cart.set(props.data._id, props.data);
      props.setCart(ct);
      props.cart.size > 0
        ? props.setState({ ...props.state, open: true })
        : props.setState({ ...props.state, open: false });
      setMessage("Remove Item");
    }
  };
  const filterAvailableIn = props.data.availableIn.filter(
    (f) => f.stateName?.toLowerCase() == filter?.toLowerCase()
  );
  const discount = Math.min(...filterAvailableIn.map((m) => m.discountPrice));
  const original = Math.min(...filterAvailableIn.map((m) => m.originalPrice));
  const discount_per = Math.trunc(((original - discount) / original) * 100);
  const details = props.data.details.split(",");
  const miniDetails = details.length < 3 ? details : details.slice(0, 3);

  return (
    <>
      <Modal open={active} onClose={() => setActive(false)}>
        <Box sx={style}>
          <IconButton
            onClick={() => setActive(false)}
            sx={{ position: "absolute", top: 0, right: 0 }}
            className="wrong-icon"
          >
            <Close />
          </IconButton>
          <Stack spacing={4}>
            <Box>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: "1rem",
                }}
              >
                {props.data.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textAlign: "center",

                  color: "#42a5f5",
                }}
              >
                Additional Information
              </Typography>
            </Box>
            <Box sx={{ overflowY: "scroll" }}>
              <ol>
                {details.map((d) => (
                  <li>{d}</li>
                ))}
              </ol>
            </Box>
            <Button variant="contained" onClick={() => setActive(false)}>
              Ok Got It
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Card sx={{ width: "95%", bgcolor: "#f3e4ff", mx: "auto", my: "1rem" }}>
        <Box
          width="3rem"
          height="1.5rem"
          px="0.5rem"
          bgcolor="#42a5f5"
          color={"white"}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius="0 0.5rem 0 0"
          sx={{ top: 0, right: 0, float: "right" }}
        >
          <Typography sx={{ fontSize: "0.7rem" }}>
            {discount_per}% off
          </Typography>
        </Box>
        <CardContent
          sx={{
            px: "1rem",
            py: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Stack
            spacing={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Stack>
              <Typography sx={{ fontSize: "1rem", fontWeight: "700" }}>
                {props.data.title}
              </Typography>
              <Typography
                sx={{ fontSize: "0.75rem", fontWeight: "600", color: "red" }}
              >
                {props.data.requirements || "REQUIRED FASTING"}
              </Typography>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mr: 0,
              }}
            >
              <Stack mr={0} spacing={0.7}>
                {props.data.parameters && (
                  <Typography variant="body2" component="p">
                    Includes : {props.data.parameters} paramters
                  </Typography>
                )}
                {miniDetails.map((m) => (
                  <Typography variant="body2" component="p">
                    {m}
                  </Typography>
                ))}
              </Stack>
              <Stack minWidth="7rem">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", fontWeight: "700" }}>
                    Starts From
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      style={{
                        textDecoration: "line-through",
                        color: "red",
                        fontSize: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      ₹{original}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1rem", fontWeight: "600", mx: "0.5rem" }}
                    >
                      ₹{discount}
                    </Typography>
                  </Box>
                </Box>
                <Button
                  sx={{ mt: "1rem" }}
                  onClick={addToCart}
                  variant="contained"
                >
                  Add
                </Button>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "3rem",
                alignItems: "center",
              }}
            >
              <Button onClick={() => setActive(true)}>Know More</Button>
              {/* <Stack>
                <Typography sx={{ fontSize: "0.7rem", fontWeight: "800" }}>
                  Available In
                </Typography>
                <Typography sx={{ fontSize: "0.7rem", fontWeight: "800" }}>
                  {
                    props.data.availableIn.filter(
                      (f) => f.stateName?.toLowerCase() == filter?.toLowerCase()
                    ).length
                  }{" "}
                  Diagnostics
                </Typography>
              </Stack> */}
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
