import { useNavigate } from "react-router-dom";
import "./CircleImage.css";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "./Avatar";
import { Box, Typography, Stack } from "@mui/material";

const CircleImage = (props: any) => {
  const navigate = useNavigate();
  return (
    <div
      className="selfCheck_icons"
      onClick={() => props.type == "menu" && navigate(`/scroll/${props.id}`)}
    >
      <div className="selfCheck_circle">
        {props.name ? (
          props.image ? (
            <img src={`https://server.gkdiagnostic.com/${props.image}`} alt="" />
          ) : (
            <Avatar {...stringAvatar(props.name)} />
          )
        ) : (
          <img src="/imgs/person.jpg" alt="" />
        )}
      </div>
      {props.name ? (
        <Stack
          sx={{ justifyContent: "center", alignItems: "center" }}
          spacing={1}
          mt={1}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "16px" },
              fontWeight: "600",
              color: " #555",
            }}
          >
            {props.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              color: " #555",
            }}
          >
            {props.number}
          </Typography>
        </Stack>
      ) : (
        <h3 style={{ cursor: "pointer" }} onClick={props.handleClick}>
          Login/sign Up
        </h3>
      )}
    </div>
  );
};

export default CircleImage;
