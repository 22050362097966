import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import CircleImage from "../components/CircleImage";
import LabTestCards from "../components/LabTestCards";
import { useLocation, useNavigate } from "react-router-dom";
import FirstIconFirst from "../image/FreeSample.png";
import FirstIconSecond from "../image/firstIcon-2.png";
import FirstIconThird from "../image/firstIcon-3.png";
import secondFirst from "../image/secondfirst.png";
import secondIcon from "../image/secondsecond.png";
import CallIcon from "@mui/icons-material/Call";
import DSlider from "../components/DSlider";
import Crousel from "../components/Crousels/Crousel";
import MobileCrousel from "../components/Crousels/MobileCrousel";
import { useDispatch, useSelector } from "react-redux";
import { getHealthissues, getScrollmenus } from "../actions/appearanceActions";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import PackageCard from "../components/Cards/packageCards";
import ImageCarousel from "../components/Crousels/imageCarousel";
import { Check, Close } from "@mui/icons-material";
import HealthIssues from "./healthIssues";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "7px",
};

const Landingpage = () => {
  const { scrollmenuList } = useSelector((state) => state.scrollmenus);
  const { healthissues } = useSelector((state) => state.healthissues);
  const { filter } = useSelector((state) => state.filter);
  console.log(filter);
  const { userInfo } = useSelector((state) => state.userLogin);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [successModal, setSuccessModal] = useState(
    Boolean(location.state?.successModal)
  );

  const navigateToPackages = () => {
    navigate(`/BookingPackages`, {
      state: { name: "Health Packages" },
    });
  };
  const navigateToTests = () => {
    navigate(`/BookingPackages`, {
      state: { name: "Health Tests" },
    });
  };
  const navigateToHealth = () => {
    navigate(`/BookingPackages`, {
      state: { name: "Scan&Imaging" },
    });
  };

  const navigateToRadiology = () => {
    navigate("/Radiology");
  };

  useEffect(() => {
    dispatch(getScrollmenus());
    dispatch(getHealthissues());
  }, [dispatch]);
  const handlenav1 = (e) => {
    e.preventDefault();
    navigate(`/BookingPackages`, {
      state: { name: "HealthPakages" },
    });
  };
  const handlenav2 = (e) => {
    e.preventDefault();
    navigate(`/BookingPackages`, {
      state: { name: "IndividualTests" },
    });
  };

  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
  });

  const [cart, setCart] = useState(new Map());
  const { vertical, horizontal, open } = state;

  const handle = () => {
    const arr = [];
    for (const x of cart.values()) {
      arr.push(x.availableIn.map((m) => m.lab));
    }
    console.log(arr, "abcd");
    const result = arr.reduce((a, b) => a.filter((c) => b.includes(c)));
    console.log(result);
    result.length > 0
      ? navigate("/selectlab", {
          state: { data: cart, avail_labs: result },
        })
      : alert("Combination is not available");
  };
  const action = (
    // <div
    //   style={{ cursor: "pointer" }}
    //   className="checkoutButton"
    //   onClick={() =>
    //     cart.size == 1
    //       ? navigate("/selectlab", { state: { data: cart } })
    //       : handle()
    //   }
    // >
    //   Show Labs
    // </div>
    <Button
      onClick={() =>
        cart.size == 1
          ? navigate("/selectlab", { state: { data: cart } })
          : handle()
      }
      variant="contained"
    >
      Show Labs
    </Button>
  );

  return (
    <div className="landing_div">
      <div className="firstForDesktop">
        <div className="first_section">
          <div className="first_section_heading">
            <div>
              <h2 className="headingfirst" style={{ color: "white" }}>
                To Book Tests, Call Us{" "}
              </h2>
              <h2 className="headingfirst" style={{ color: "#FFFF00" }}>
                <CallIcon fontSize="9px" />
                {/* <span style={{ fontStyle: "italic" }}> 86 86 073 073</span> */}
              <a style={{ color: "#FFFF00" }} href="tel:09441805252" >
                  <span> 094418 05252</span>
        </a>
        </h2>
            </div>

            <button className="buttonStyle1" onClick={navigateToTests}>
              BOOK NOW
            </button>
          </div>
          <div className="iconWithLable">
            <div className="Section_Icon">
              <div className="icon-label">
                <img src={FirstIconFirst} alt="" />
                <div style={{ marginBottom: "8px" }} className="label">
                  Free Home Collection
                </div>
              </div>
              <div className="icon-label">
                <img src={FirstIconSecond} alt="" />
                <div style={{ marginBottom: "8px" }} className="label">
                  Reports In 6Hrs
                </div>
              </div>
              <div className="icon-label">
                <img src={FirstIconThird} alt="" />
                <div style={{ marginBottom: "8px" }} className="label">
                  At Work Or At Home
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second_section">
          <div className="secondsection_first" onClick={navigateToPackages}>
            <h2 className="mobileElement">Health Packages</h2>
            <div className="text_blue desktopElement">Upto 50% off</div>

            <div className="secondsection_subflex">
              <div className="subflex_icon">
                <img src={secondFirst} alt="" />
              </div>
              <div className="subflex_text">
                <h2 className="desktopElement">Health Packages</h2>
                <div className="text_blue mobileElement">Upto 50% off</div>
                <h4>NABL & ICMR</h4>
                <h4>APPROVED LABS</h4>
              </div>
            </div>
          </div>

          <div className="secondsection_Second" onClick={navigateToHealth}>
            <h2 className="mobileElement">Health Scans & Imagings</h2>
            <div className="text_blue desktopElement">Upto 30% off</div>
            <div className="secondsection_subflex">
              <div className="subflex_icon">
                {" "}
                <img src={secondIcon} alt="" />
              </div>
              <div className="subflex_text">
                <h2 className="desktopElement scantext">
                  Health Scans & Imagings
                </h2>
                <div className="text_blue mobileElement ">Upto 30% off</div>
                <h4 className="discounttext">Advanced &</h4>
                <h4 className="name">Affordable Radiology</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="third_section mobileElement">
        {/* <MobileCrousel /> */}
        <ImageCarousel />
      </div>
      <div className="third_section desktopElement">
        {/* <DSlider /> */}
        <ImageCarousel />
      </div>
      <div className="fourth_section">
        <div className="fourth_section_heading">Most Common Self Checks</div>
        <div className="SelfCheck_slider">
          {scrollmenuList.map((m) => (
            <CircleImage name={m.title} image={m.icon} type="menu" id={m._id} />
          ))}
        </div>
      </div>
      <Box px={{ xs: 1, sm: 3, md: 4 }}>
        <div className="fourth_section_heading">Most Common Health Issues</div>
        <Stack
          direction="row"
          sx={{
            overflowX: { xs: "auto", lg: "hidden" },
            overflowY: "hidden",
            pr: "2rem",
            mr: "7px",
            pl: { xs: 0, md: 2 },
          }}
        >
          {healthissues.map((m, index) => (
            <Box
              width="180px"
              mr={{ xs: "10px", sm: "13px", md: "30px" }}
              ml={{ xs: 0, sm: 2 }}
            >
              <HealthIssues index={index} data={m} />
            </Box>
          ))}
        </Stack>
      </Box>
      <div className="fifth_section">
        <div className="fifth_section_heading">
          Most Commonly Booked Tests{" "}
          <button onClick={navigateToTests}>see more</button>
        </div>
        {/* <div className="l"> */}
        <Box sx={{ width: "100%" }}>
          <Crousel
            crousalData="Tests"
            loc={filter}
            cart={cart}
            setCart={setCart}
            state={state}
            setState={setState}
          />
        </Box>
        <Snackbar
          sx={{ marginBottom: "5rem" }}
          className="snackbar"
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          message={`${cart.size} item in Cart`}
          action={action}
          key={vertical + horizontal}
        />
      </div>
      <div className="fifth_section">
        <div className="fifth_section_heading">
          Most Commonly Booked Pakages{" "}
          <button onClick={navigateToPackages}>see more</button>
        </div>
        <div className="">
          <Crousel
            crousalData="pakages"
            loc={filter}
            cart={cart}
            setCart={setCart}
            state={state}
            setState={setState}
          />

          <Snackbar
            sx={{ marginBottom: "5rem" }}
            className="snackbar"
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            message={`${cart.size} item in Cart`}
            action={action}
            key={vertical + horizontal}
          />
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={successModal}
        closeAfterTransition
        onClose={() => setSuccessModal(false)}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={successModal}>
          <Box sx={style1}>
            <IconButton
              onClick={() => setSuccessModal(false)}
              sx={{ position: "absolute", top: 0, right: 0, zIndex: 3 }}
              className="wrong-icon"
            >
              <Close />
            </IconButton>
            <Stack spacing={2} alignItems="center">
              <IconButton sx={{ border: "2px solid #1565c0" }}>
                <Check sx={{ color: "#1565c0" }} />
              </IconButton>
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>
                Hey {userInfo?.name}!
              </Typography>
              <Stack spacing={0.7} alignItems="center">
                <Typography sx={{ fontSize: "1.2rem", fontWeight: 700 }}>
                  Your Order is Placed!
                </Typography>
                <Typography mb={2} sx={{ fontSize: "0.9rem", fontWeight: 500 }}>
                  Thank you for booking your test with us! Your test has been
                  booked successfully
                </Typography>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  fontWeight: 700,
                }}
                onClick={() => navigate("/MyBooking")}
              >
                Track Order
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Landingpage;
